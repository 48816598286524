import React from 'react';
import * as Sentry from '@sentry/nextjs';
interface Props {
  children: React.ReactNode;
}
interface State {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(): State {
    return {
      hasError: false
    }; // Keep rendering the normal UI
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Silently report to Sentry
    Sentry.captureException(error, {
      extra: {
        componentStack: errorInfo.componentStack
      }
    });
  }
  render(): React.ReactNode {
    // Always render children, even if there was an error
    return this.props.children;
  }
}
export default ErrorBoundary;